import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {

  const date = new Date();

  let message = '';
  if(date.getDate() === 22 && date.getMonth() === 12) {
    message = 'Its my birthday today!!!!';
  } 

  const msg = (a: string) => a !== '' ? (<p>{message}</p>) : ''

  return (
    <div className="App">
      <header className="App-header">
        <p>On this day, <q><i>David James Thorn</i></q> was born:</p>
        <h1 className='widen'>22 December 1978</h1>
        {msg(message)}
      </header>
    </div>
  );
}

export default App;
